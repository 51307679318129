import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import Cookie from 'src/lib/cookies';
import type { AppThunk } from '../store';
import type {
  Board,
  Card,
  CheckListItem,
  Checklist,
  Column,
  Comment,
  Member
} from '../apis/types/KanbanAPITypes';
import objFromArray from '../utils/objFromArray';
import KanbanAPI from '../apis/KanbanAPI';

interface KanbanState {
  isLoaded: boolean;
  isModalOpen: boolean,
  editBoardId: string,
  board: {
    byId: Record<string, Board>;
    allIds: string[];
    allBoards: Board[];
  };
  columns: {
    byId: Record<string, Column>;
    allIds: string[];
  };
  cards: {
    byId: Record<string, Card>;
    allIds: string[];
  };
  members: {
    byId: Record<string, Member>;
    allIds: string[];
  };
}

const initialState: KanbanState = {
  isLoaded: false,
  isModalOpen: false,
  editBoardId: null,
  board: {
    byId: {},
    allIds: [],
    allBoards: [],
  },
  columns: {
    byId: {},
    allIds: [],
  },
  cards: {
    byId: {},
    allIds: []
  },
  members: {
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    getBoards(
      state: KanbanState,
      action: PayloadAction<{ boards: Board[] }>
    ): void {
      const { boards } = action.payload;
      state.board.allIds = [];
      state.board.allBoards = [];

      state.board.byId = objFromArray(boards, 'boardId');

      boards.forEach((element) => {
        state.board.allBoards.push(element);
        state.board.allIds.push(element.boardId);
      });
    },
    getBoardById(
      state: KanbanState,
      action: PayloadAction<{ board: Board }>
    ): void {
      const { board } = action.payload;
      if (!state.board.allIds[board.boardId]) {
        board.id = state.board.allIds.length + 1;
        state.board.allIds.push(board.boardId);
        state.board.byId[board.boardId] = board;
      }

      Object.assign(state.board.byId[board.boardId], board);

      board.columns.forEach((col) => {
        state.columns.byId[col.columnId] = col;
        if (!state.columns.allIds[col.columnId]) {
          state.columns.allIds.push(col.columnId);
          state.columns.byId[col.columnId] = col;
        }
        col.cards.forEach((c) => {
          if (!state.cards.allIds[c.cardId]) {
            state.cards.allIds.push(c.cardId);
            state.cards.byId[c.cardId] = c;
          }
        });
      });
    },
    updateBoard(
      state: KanbanState,
      action: PayloadAction<{ board: Board }>
    ): void {
      const { board } = action.payload;
      const res = state.board.allBoards.map((_n) => {
        if (_n.boardId === board.boardId) {
          return board;
        }
        return _n;
      });

      Object.assign(state.board.allBoards, res);
      state.board.byId[board.boardId] = board;
      if (!state.board.allIds.includes(board.boardId)) {
        state.board.allIds.push(board.boardId);
      }
    },
    createBoard(
      state: KanbanState,
      action: PayloadAction<{ board: Board }>
    ): void {
      const { board } = action.payload;
      state.board.allBoards.push(board);
      state.board.allIds.push(board.boardId);
      state.board.byId = objFromArray(state.board.allBoards, 'boardId');
    },
    createColumn(
      state: KanbanState,
      action: PayloadAction<{ column: Column }>
    ): void {
      const { column } = action.payload;

      state.columns.byId[column.columnId] = column;
      state.columns.allIds.push(column.columnId);

      state.board.byId[column.boardId].columns.push(column);
      state.board.allBoards.forEach((e) => {
        if (e.boardId === column.boardId) {
          e.columns.push(column);
        }
      });
    },
    updateColumn(
      state: KanbanState,
      action: PayloadAction<{ column: Column }>
    ): void {
      const { column } = action.payload;
      state.columns.byId[column.columnId] = column;
    },
    clearColumn(
      state: KanbanState,
      action: PayloadAction<{ columnId: string }>
    ): void {
      const { columnId } = action.payload;

      // cardIds to be removed
      const { cards } = state.columns.byId[columnId];

      const removeIds = [];
      cards.forEach((item) => {
        removeIds.push(item.cardId);
      });

      // Delete the cardIds references from the column
      state.columns.byId[columnId].cards = [];

      // Delete the cards from state
      cards.forEach((card) => {
        delete state.cards.byId[card.cardId];
      });

      // todo: this is n^2 should optimize in the future
      const filteredIds = [];
      state.cards.allIds.forEach((id) => {
        if (!removeIds.includes(id)) {
          filteredIds.push(id);
        }
      });

      state.cards.allIds = filteredIds;
    },
    deleteColumn(
      state: KanbanState,
      action: PayloadAction<{ boardId: string, columnId: string }>
    ): void {
      const { boardId, columnId } = action.payload;

      delete state.columns.byId[columnId];
      state.columns.allIds = state.columns.allIds.filter((_listId) => _listId !== columnId);

      state.board.byId[boardId].columns = (
        state.board.byId[boardId].columns.filter((c) => c.columnId !== columnId)
      );
    },
    createCard(
      state: KanbanState,
      action: PayloadAction<{ card: Card }>
    ): void {
      const { card } = action.payload;

      state.cards.byId[card.cardId] = card;
      state.cards.allIds.push(card.cardId);

      // Add the cardId reference to the column
      state.columns.byId[card.columnId].cards?.push(card);
    },
    getCard(
      state: KanbanState,
      action: PayloadAction<{ card: Card }>
    ): void {
      const { card } = action.payload;

      // Object.assign(state.cards.byId[card.cardId], card);
      state.cards.byId[card.cardId] = card;
      if (!state.cards.allIds[card.cardId]) {
        state.cards.allIds.push(card.cardId);
      }
    },
    updateCard(
      state: KanbanState,
      action: PayloadAction<{ card: Card }>
    ): void {
      const { card } = action.payload;

      Object.assign(state.cards.byId[card.cardId], card);
    },
    moveCard(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; cardPosition: number; colId?: string }>
    ): void {
      const { cardId, cardPosition, colId } = action.payload;
      const sourceColumnId = state.cards.byId[cardId].columnId;
      const card = state.cards.byId[cardId];
      // Remove card from source column
      state.columns.byId[sourceColumnId].cards = (
        state.columns.byId[sourceColumnId].cards.filter((_cardId) => _cardId.cardId !== cardId)
      );

      // If columnId exists, it means that we have to add the card to the new column
      if (colId) {
        // Change card's columnId reference
        state.cards.byId[card.cardId].columnId = colId;
        // Push the cardId to the specified position
        state.columns.byId[colId].cards.splice(cardPosition, 0, card);
      } else {
        // Push the cardId to the specified position
        state.columns.byId[sourceColumnId].cards.splice(cardPosition, 0, card);
      }
    },
    deleteCard(
      state: KanbanState,
      action: PayloadAction<{ cardId: string }>
    ): void {
      const { cardId } = action.payload;
      const { columnId } = state.cards.byId[cardId];

      delete state.cards.byId[cardId];
      state.cards.allIds = state.cards.allIds.filter((_cardId) => _cardId !== cardId);
      state.columns.byId[columnId].cards = (
        state.columns.byId[columnId].cards.filter((card) => card.cardId !== cardId)
      );
    },
    addComment(
      state: KanbanState,
      action: PayloadAction<{ comment: Comment }>
    ): void {
      const { comment } = action.payload;
      const card = state.cards.byId[comment.cardId];

      card.comments.push(comment);
    },
    addChecklist(
      state: KanbanState,
      action: PayloadAction<{ checklist: Checklist }>
    ): void {
      const { checklist } = action.payload;
      const card = state.cards.byId[checklist.cardId];

      card.checkLists.push(checklist);
    },
    updateChecklist(
      state: KanbanState,
      action: PayloadAction<{ checklist: Checklist }>
    ): void {
      const { checklist } = action.payload;
      const card = state.cards.byId[checklist.cardId];

      card.checkLists = card.checkLists.map((_checklist) => {
        if (_checklist.checklistId === checklist.checklistId) {
          return checklist;
        }

        return _checklist;
      });
    },
    deleteChecklist(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklistId: string }>
    ): void {
      const { cardId, checklistId } = action.payload;
      const card = state.cards.byId[cardId];

      card.checkLists = card.checkLists.filter((checklist) => checklist.checklistId !== checklistId);
    },
    addCheckItem(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checkItem: CheckListItem }>
    ): void {
      const { cardId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];
      const checklist = card.checkLists.find((_checklist) => _checklist.checklistId === checkItem.checklistId);

      checklist.checkListItems.push(checkItem);
    },
    updateCheckItem(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checkItem: CheckListItem }>
    ): void {
      const { cardId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];
      const checklist = card.checkLists.find((_checklist) => _checklist.checklistId === checkItem.checklistId);

      checklist.checkListItems = checklist.checkListItems.map((_checkItem) => {
        if (_checkItem.checklistItemId === checkItem.checklistItemId) {
          return checkItem;
        }

        return _checkItem;
      });
    },
    deleteCheckItem(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklistId: string; checkItemId: string }>
    ): void {
      const { cardId, checklistId, checkItemId } = action.payload;
      const card = state.cards.byId[cardId];
      const checklist = card.checkLists.find((_checklist) => _checklist.checklistId === checklistId);

      checklist.checkListItems = (
        checklist.checkListItems.filter((checkItem) => checkItem.checklistItemId !== checkItemId)
      );
    },
    openModal(state: KanbanState): void {
      state.isModalOpen = true;
    },
    closeModal(state: KanbanState): void {
      state.isModalOpen = false;
      state.editBoardId = null;
    },
    editBoard(
      state: KanbanState,
      action: PayloadAction<{ boardId: string; }>
    ): void {
      const { boardId } = action.payload;
      state.isModalOpen = true;
      state.editBoardId = boardId;
    },
  }
});
export const { reducer } = slice;

// get simple board data for customer
export const getBoards = (): AppThunk => async (dispatch): Promise<void> => {
  const uuid = Cookie.getCookie(Cookie.Keys.UUID);
  const boards = await KanbanAPI.getBoards(uuid); // todo: hardcoded need to fix
  await dispatch(slice.actions.getBoards({ boards }));
};

export const createBoard = (board: Board): AppThunk => async (dispatch): Promise<void> => {
  const resp = await KanbanAPI.upsertBoard(board);
  await dispatch(slice.actions.createBoard({ board: resp }));
};

export const updateBoard = (board: Board): AppThunk => async (dispatch): Promise<void> => {
  const resp = await KanbanAPI.upsertBoard(board);
  await dispatch(slice.actions.updateBoard({ board: resp }));
};

export const getBoardById = (boardId: string): AppThunk => async (dispatch): Promise<void> => {
  const uuid = Cookie.getCookie(Cookie.Keys.UUID);
  const board = await KanbanAPI.getBoard(boardId);
  await dispatch(slice.actions.getBoardById({ board }));
};

// need boardId
export const createColumn = (column: Column): AppThunk => async (dispatch): Promise<void> => {
  column = await KanbanAPI.upsertColumn(column);
  await dispatch(slice.actions.createColumn({ column }));
};

// need boardId
export const updateColumn = (
  column: Column
): AppThunk => async (dispatch): Promise<void> => {
  const resp = await KanbanAPI.upsertColumn(column);
  await dispatch(slice.actions.updateColumn({ column }));
};

// don't have this api :/ using this hybrid for now
export const clearColumn = (columnId: string): AppThunk => async (dispatch): Promise<void> => {
  const column = await KanbanAPI.getColumn(columnId);
  await column.cards.forEach(async (element) => {
    if (element) {
      await KanbanAPI.deleteCard(element.cardId);
    }
  });
  await dispatch(slice.actions.clearColumn({ columnId }));
};

export const deleteColumn = (boardId: string, columnId: string): AppThunk => async (dispatch): Promise<void> => {
  const response = await KanbanAPI.deleteColumn(columnId);
  await dispatch(slice.actions.deleteColumn({ boardId, columnId }));
};

export const createCard = (
  card: Card
): AppThunk => async (dispatch): Promise<void> => {
  card = await KanbanAPI.upsertCard(card);
  await dispatch(slice.actions.createCard({ card }));
};

// need to delcare card model
export const updateCard = (
  card: Card
): AppThunk => async (dispatch): Promise<void> => {
  card = await KanbanAPI.upsertCard(card);
  await dispatch(slice.actions.updateCard({ card }));
};

export const getCard = (
  cardId: string
): AppThunk => async (dispatch): Promise<void> => {
  const card = await KanbanAPI.getCard(cardId);
  await dispatch(slice.actions.getCard({ card }));
};

export const moveCard = (
  cardId: string,
  cardPosition: number,
  uuid: string,
  colId?: string,
): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.moveCard({ cardId, cardPosition, colId }));
  const card = await KanbanAPI.getCard(cardId);
  const prevPosition = card.position;
  const prevColumn = card.columnId;
  let updatedCard = { ...card, ...{ position: cardPosition }, ...{ customerUUID: uuid } };
  if (colId) {
    const colObj = { columnId: colId };
    updatedCard = { ...updatedCard, ...colObj };
  }
  const result = await KanbanAPI.upsertCard(updatedCard);
  if (!result) {
    cardPosition = prevPosition;
    colId = prevColumn;
    dispatch(slice.actions.moveCard({ cardId, cardPosition, colId }));
  }
};

export const deleteCard = (cardId: string): AppThunk => async (dispatch): Promise<void> => {
  const response = await KanbanAPI.deleteCard(cardId);
  await dispatch(slice.actions.deleteCard({ cardId }));
};

export const addComment = (
  comment: Comment
): AppThunk => async (dispatch): Promise<void> => {
  comment = await KanbanAPI.upsertComment(comment);
  await dispatch(slice.actions.addComment({ comment }));
};

export const addChecklist = (
  checklist: Checklist
): AppThunk => async (dispatch): Promise<void> => {
  checklist = await KanbanAPI.upsertChecklist(checklist);
  await dispatch(slice.actions.addChecklist({ checklist }));
};

export const updateChecklist = (
  checklist: Checklist,
): AppThunk => async (dispatch): Promise<void> => {
  checklist = await KanbanAPI.upsertChecklist(checklist);
  await dispatch(slice.actions.updateChecklist({ checklist }));
};

export const deleteChecklist = (
  cardId: string,
  checklistId: string
): AppThunk => async (dispatch): Promise<void> => {
  const response = await KanbanAPI.deleteChecklist(checklistId);
  await dispatch(slice.actions.deleteChecklist({ cardId, checklistId }));
};

export const addCheckItem = (
  cardId: string,
  checkItem: CheckListItem
): AppThunk => async (dispatch): Promise<void> => {
  checkItem = await KanbanAPI.upsertChecklistItem(checkItem);

  await dispatch(slice.actions.addCheckItem({
    cardId,
    checkItem
  }));
};

export const updateCheckItem = (
  cardId: string,
  checkItem: CheckListItem
): AppThunk => async (dispatch): Promise<void> => {
  checkItem = await KanbanAPI.upsertChecklistItem(checkItem);

  await dispatch(slice.actions.updateCheckItem({
    cardId,
    checkItem
  }));
};

export const deleteCheckItem = (
  cardId: string,
  checklistId: string,
  checkItemId: string
): AppThunk => async (dispatch): Promise<void> => {
  const response = await KanbanAPI.deleteChecklistItem(checkItemId);
  await dispatch(slice.actions.deleteCheckItem({
    cardId,
    checklistId,
    checkItemId
  }));
};

export const openModal = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.openModal());
};

export const closeModal = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.closeModal());
};

export const deleteBoard = (
  boardId: string
): AppThunk => async (dispatch): Promise<void> => {
  await KanbanAPI.deleteBoard(boardId);
};

export const editBoard = (
  boardId: string
): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.editBoard({ boardId }));
};

export default slice;
